import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import AgbText from '../components/agbText';

import { ReactComponent as Icon1 } from '../assets/svg/Jahre.svg';
import { ReactComponent as Icon2 } from '../assets/svg/Mindestalter.svg';
import { ReactComponent as Icon3 } from '../assets/svg/Ausweis.svg';
import { ReactComponent as Icon4 } from '../assets/svg/Vorkasse.svg';
import { ReactComponent as Icon5 } from '../assets/svg/Mietvertrag.svg';

import { ReactComponent as Icon6 } from '../assets/svg/Vignette.svg';
import { ReactComponent as Icon7 } from '../assets/svg/Vollkaskoagb.svg';
import { ReactComponent as Icon7b } from '../assets/svg/Begrenzung.svg';

import { ReactComponent as Icon8 } from '../assets/svg/Weitergabe.svg';
import { ReactComponent as Icon9 } from '../assets/svg/Rauchen.svg';
import { ReactComponent as Icon10 } from '../assets/svg/Rennen.svg';
import { ReactComponent as Icon11 } from '../assets/svg/LaunchControl.svg';
import { ReactComponent as Icon12 } from '../assets/svg/Drifts.svg';
import { ReactComponent as Icon13 } from '../assets/svg/AusserhalbOesterreich.svg';
import { ReactComponent as Icon14 } from '../assets/svg/Alkohol.svg';
import { ReactComponent as Icon15 } from '../assets/svg/Strassen.svg';
import { ReactComponent as Icon16 } from '../assets/svg/Tranktionskontrolle.svg';

import { ReactComponent as Icon17 } from '../assets/svg/Puenktlich.svg';
import { ReactComponent as Icon18 } from '../assets/svg/Sauber.svg';
import { ReactComponent as Icon19 } from '../assets/svg/Vollgetankt.svg';

const AgbPage = () => (
  <Layout bg="bg-peak">
    <Seo title="Allgemeine Vermietbedingungen (AGB) - Das wichtigste in Kürze" />
    <div className="pt-32 text-center boxed generic-page">
      <div>
        <h1 data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease" data-sal-duration="500" className="lg:!text-5xl">
          Allgemeine Vermiet&shy;bedingungen (AGB)
        </h1>
        <h2 data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease" data-sal-duration="500">
          Das wichtigste in Kürze
        </h2>

        <div className="py-12">
          <h2 className="font-arboria lg:!text-4xl text-gray-500" data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease" data-sal-duration="500">
            Unsere Voraussetzungen
          </h2>
          <dl
            className="py-10 space-y-10 text-center md:space-y-0 md:grid md:grid-cols-3 md:gap-x-16 md:gap-y-6"
            data-sal="slide-up"
            data-sal-delay="200"
            data-sal-easing="ease"
            data-sal-duration="500"
          >
            <div className="relative">
              <dd className="w-full my-4 text-center">
                <Icon1 className="w-16 h-16 m-auto text-white fill-current" />
              </dd>
              <dt>
                <p className="text-lg font-medium leading-6 lg:mt-20 text-primary-font font-arboria">Mindestalter 25 Jahre</p>
              </dt>
            </div>

            <div className="relative">
              <dd className="w-full my-4 text-center">
                <Icon2 className="w-16 h-16 m-auto" />
              </dd>
              <dt>
                <p className="text-lg font-medium leading-6 lg:mt-20 text-primary-font font-arboria">Führerschein mind. 3 Jahr</p>
              </dt>
            </div>

            <div className="relative">
              <dd className="w-full my-4 text-center">
                <Icon3 className="w-16 h-16 m-auto text-white fill-current" />
              </dd>
              <dt>
                <p className="text-lg font-medium leading-6 lg:mt-20 text-primary-font font-arboria">Gültiger Lichtbildausweis</p>
              </dt>
            </div>

            <div className="relative">
              <dd className="w-full my-4 text-center">
                <Icon4 className="w-16 h-16 m-auto text-white fill-current" />
              </dd>
              <dt>
                <p className="text-lg font-medium leading-6 lg:mt-20 text-primary-font font-arboria">Vorauszahlung</p>
              </dt>
            </div>

            <div className="relative">
              <dd className="w-full my-4 text-center">
                <Icon5 className="w-16 h-16 m-auto text-white fill-current" />
              </dd>
              <dt>
                <p className="text-lg font-medium leading-6 lg:mt-20 text-primary-font font-arboria">Unterzeichneter Mietvertrag</p>
              </dt>
            </div>
          </dl>

          <h2 data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease" data-sal-duration="500" className="font-arboria lg:!text-4xl pt-12 text-gray-500">
            Diese Leistungen sind inkludiert
          </h2>
          <dl
            data-sal="slide-up"
            data-sal-delay="200"
            data-sal-easing="ease"
            data-sal-duration="500"
            className="py-10 space-y-10 text-center md:space-y-0 md:grid md:grid-cols-3 md:gap-x-16 md:gap-y-6"
          >
            <div className="relative">
              <dd className="w-full my-4 text-center">
                <Icon6 className="w-16 h-16 m-auto text-white fill-current" />
              </dd>
              <dt>
                <p className="text-lg font-medium leading-6 lg:mt-20 text-primary-font font-arboria">Digitale Vignette für Österreich</p>
              </dt>
            </div>

            <div className="relative">
              <dd className="w-full my-4 text-center">
                <Icon7 className="w-16 h-16 m-auto text-white fill-current" />
              </dd>
              <dt>
                <p className="text-lg font-medium leading-6 lg:mt-20 text-primary-font font-arboria">Vollkaskoversicherung mit Selbstbehalt</p>
              </dt>
            </div>

            <div className="relative">
              <dd className="w-full my-4 text-center">
                <Icon7b className="w-16 h-16 m-auto text-white fill-current" />
              </dd>
              <dt>
                <p className="text-lg font-medium leading-6 lg:mt-20 text-primary-font font-arboria">100 Freikilometer /Tag</p>
              </dt>
            </div>
          </dl>

          <h2 data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease" data-sal-duration="500" className="font-arboria lg:!text-4xl pt-12 text-gray-500">
            Was ist nicht erlaubt?
          </h2>
          <dl
            data-sal="slide-up"
            data-sal-delay="200"
            data-sal-easing="ease"
            data-sal-duration="500"
            className="py-10 space-y-10 text-center md:space-y-0 md:grid md:grid-cols-3 md:gap-x-16 md:gap-y-6"
          >
            <div className="relative">
              <dd className="w-full my-4 text-center">
                <Icon8 className="w-16 h-16 m-auto text-white fill-current" />
              </dd>
              <dt>
                <p className="text-lg font-medium leading-6 lg:mt-20 text-primary-font font-arboria">Weitergabe an Dritte</p>
              </dt>
            </div>

            <div className="relative">
              <dd className="w-full my-4 text-center">
                <Icon9 className="w-16 h-16 m-auto text-white fill-current" />
              </dd>
              <dt>
                <p className="text-lg font-medium leading-6 lg:mt-20 text-primary-font font-arboria">Rauchen im Fahrzeug</p>
              </dt>
            </div>

            <div className="relative">
              <dd className="w-full my-4 text-center">
                <Icon10 className="w-16 h-16 m-auto text-white fill-current" />
              </dd>
              <dt>
                <p className="text-lg font-medium leading-6 lg:mt-20 text-primary-font font-arboria">Straßenrennen</p>
              </dt>
            </div>

            <div className="relative">
              <dd className="w-full my-4 text-center">
                <Icon11 className="w-16 h-16 m-auto text-white fill-current" />
              </dd>
              <dt>
                <p className="text-lg font-medium leading-6 lg:mt-20 text-primary-font font-arboria">Launch Control</p>
              </dt>
            </div>

            <div className="relative">
              <dd className="w-full my-4 text-center">
                <Icon12 className="w-16 h-16 m-auto text-white fill-current" />
              </dd>
              <dt>
                <p className="text-lg font-medium leading-6 lg:mt-20 text-primary-font font-arboria">Drifts und Burn Outs</p>
              </dt>
            </div>

            <div className="relative">
              <dd className="w-full my-4 text-center">
                <Icon13 className="w-16 h-16 m-auto text-white fill-current" />
              </dd>
              <dt>
                <p className="text-lg font-medium leading-6 lg:mt-20 text-primary-font font-arboria">Fahrten ausserhalb AUT und GER</p>
              </dt>
            </div>

            <div className="relative">
              <dd className="w-full my-4 text-center">
                <Icon14 className="w-16 h-16 m-auto text-white fill-current" />
              </dd>
              <dt>
                <p className="text-lg font-medium leading-6 lg:mt-20 text-primary-font font-arboria">Fahrten unter Alkohol- oder Drogeneinfluss</p>
              </dt>
            </div>

            <div className="relative">
              <dd className="w-full my-4 text-center">
                <Icon15 className="w-16 h-16 m-auto text-white fill-current" />
              </dd>
              <dt>
                <p className="text-lg font-medium leading-6 lg:mt-20 text-primary-font font-arboria">Fahrten auf nicht öffentlichen Straßen</p>
              </dt>
            </div>

            <div className="relative">
              <dd className="w-full my-4 text-center">
                <Icon16 className="w-16 h-16 m-auto text-white fill-current" />
              </dd>
              <dt>
                <p className="text-lg font-medium leading-6 lg:mt-20 text-primary-font font-arboria">Deaktivieren der Traktionskontrolle</p>
              </dt>
            </div>
          </dl>

          <h2 data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease" data-sal-duration="500" className="font-arboria lg:!text-4xl pt-12 text-gray-500">
            Rückgabe
          </h2>
          <dl
            data-sal="slide-up"
            data-sal-delay="200"
            data-sal-easing="ease"
            data-sal-duration="500"
            className="py-10 space-y-10 text-center md:space-y-0 md:grid md:grid-cols-3 md:gap-x-16 md:gap-y-6"
          >
            <div className="relative">
              <dd className="w-full my-4 text-center">
                <Icon17 className="w-16 h-16 m-auto text-white fill-current" />
              </dd>
              <dt>
                <p className="text-lg font-medium leading-6 lg:mt-20 text-primary-font font-arboria">Pünktlich</p>
              </dt>
            </div>

            <div className="relative">
              <dd className="w-full my-4 text-center">
                <Icon18 className="w-16 h-16 m-auto text-white fill-current" />
              </dd>
              <dt>
                <p className="text-lg font-medium leading-6 lg:mt-20 text-primary-font font-arboria">Sauber</p>
              </dt>
            </div>

            <div className="relative">
              <dd className="w-full my-4 text-center">
                <Icon19 className="w-16 h-16 m-auto text-white fill-current" />
              </dd>
              <dt>
                <p className="text-lg font-medium leading-6 lg:mt-20 text-primary-font font-arboria">Vollgetankt</p>
              </dt>
            </div>
          </dl>
        </div>

        <p className="pt-12">Die vollständigen Vermietbedingungen zum Download</p>
        <a href={'/Mietbedingungen.pdf'} target="_blank" rel="noreferrer" className="inset-0 block pb-12 m-auto w-fit">
          <button className="block px-6 py-2 text-white border border-transparent rounded bg-secondary-font hover:bg-transparent hover:border-white">
            Vermietbedingungen als PDF{' '}
            <svg x="0" y="0" viewBox="0 0 960 560" class="w-5 fill-current text-white inline -rotate-90">
              <path d="M480 344.181L268.869 131.889c-15.756-15.859-41.3-15.859-57.054 0-15.754 15.857-15.754 41.57 0 57.431l237.632 238.937c8.395 8.451 19.562 12.254 30.553 11.698 10.993.556 22.159-3.247 30.555-11.698L748.186 189.32c15.756-15.86 15.756-41.571 0-57.431s-41.299-15.859-57.051 0L480 344.181z"></path>
            </svg>
          </button>
        </a>
      </div>
    </div>
    <div className="w-full bg-primary-bg">
      <div className="py-24 text-left boxed generic-page">
        <AgbText />
      </div>
    </div>
  </Layout>
);

export default AgbPage;
