import React from 'react';
// import { Link } from "gatsby"

function agbText() {
  return (
    <>
      <h2>
        <span>PEAK MOTORS, </span>
        <span>Peakmedia Vertriebs GmbH, Wildbichler Straße 31, 6341 Ebbs </span>
      </h2>
      <p>
        <span>Stand 02.02.2022 </span>
      </p>
      <h3>
        <span>A: Parteien/Geltungsbereich </span>
      </h3>
      <ol className="ml-8 py-8 !list-decimal">
        <li>
          <p>
            <span>
              Der Mietvertrag wird zwischen Peakmedia Vertriebs GmbH, FN 486808 w als Vermieterin einerseits und dem im Mietvertrag genannten Mieter abgeschlossen. Dem
              gegenständlichen Mietvertrag liegen die nachstehenden Mitbedingungen im Sinne von allgemeinen Geschäftsbedingungen zugrunde; abweichende Vertragsbestimmungen des
              Mieters wie insbesondere dessen AGB entfalten keine Rechtswirksamkeit.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Der Mieter nimmt zur Kenntnis, dass mehrere Mieter sowie die im Mietvertrag angegeben Fahrzeuglenker dem Vermieter für die Einhaltung des Mietvertrages solidarisch
              haften. Der Mieter ist verpflichtet, einen im Mietvertrag genannten Lenker die AGB zu überbinden; sie haben die Vermieterin für den Fall der Nichtüberbindung der AGB
              für alle Schäden die daraus resultieren schadund klaglos zu halten. Der Mieter trägt auch die Verantwortung dafür, dass das Fahrzeug nur Lenkern übergeben wird, die
              im Mietvertrag genannt und im Besitz einer gültigen Lenkerberechtigung ist.{' '}
            </span>
          </p>
        </li>
      </ol>
      <h3>
        <span>B: Fahrzeugzustand, Reparaturen, Betriebsmittel </span>
      </h3>
      <ol className="ml-8 py-8 !list-decimal">
        <li>
          <p>
            <span>
              Der Mieter ist verpflichtet, das Fahrzeug schonend zu behandeln. Er hat alle für die Benutzung eines derartigen Fahrzeuges maßgeblichen Vorschriften (insbesondere das
              Kraftfahrgesetz und die Straßenverkehrsordnung) zu beachten und während der Mietdauer regelmäßig zu prüfen, ob sich das Fahrzeug auch weiterhin in betriebsund
              verkehrssicherem Zustand befindet. Vor Fahrtantritt hat sich der Mieter mittels des im Fahrzeug befindlichen Betriebshandbuches über die richtige Bedienung des
              Fahrzeuges zu informieren und die diesbezüglichen Vorschriften und Empfehlungen einzuhalten (insbesondere regelmäßige Prüfung des ausreichenden Standes von Motoröl,
              Kühlflüssigkeit und sonstigen Betriebsmitteln).{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Bei Fahrzeugübernahme bereits bestehende Schäden am Fahrzeug sind vom Mieter, sofern diese nicht auf dem Mietvertrag bzw. Übergabeprotokoll bereits verzeichnet sind,
              dem Vermieter sofort, also vor Fahrtantritt, zu melden. Meldet der Mieter derartige Schäden nicht sofort, gelten diese als von ihm verursacht, sofern er nicht das
              Gegenteil beweist.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Der Mieter oder eingetragene Fahrer wurde im Zuge der Fahrzeugübergabe über technische Einrichtungen des Fahrzeuges sowie dessen Sicherheitseinrichtungen wie z.B.
              Verbandskasten, Warnwesten, Pannendreieck etc. instruiert.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Der Mieter ist nicht berechtigt, den Vermieter rechtsgeschäftlich zu vertreten; es ist dem Mieter dementsprechend auch nicht gestattet, eigenmächtig, das heißt ohne
              Einholung der vorherigen ausdrücklichen Zustimmung des Vermieters, Reparaturarbeiten am Fahrzeug in Auftrag zu geben oder eigenmächtig durchzuführen.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Dem Mieter wird das Fahrzeug mit vollem Kraftstofftank übergeben. Im Gegenzug hat der Mieter das Fahrzeug bei Beendigung des Mietverhältnisses ebenso mit einem vollen
              Kraftstofftank zurückzustellen. Wird das Fahrzeug nicht nachweislich (Vorlage des Tankbeleges) vollgetankt retourniert, erfolgt eine Betankung durch die Vermieterin
              gemäß den Bestimmungen und Preisen der jeweils gültigen aktuellen Tariftabelle.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Getankt werden darf lediglich jene Art von Kraftstoff, die im Betriebshandbuch des Fahrzeuges oder am Tankdeckel angeführt ist. Der Mieter haftet dem Vermieter für
              jeden durch Falsch-Betankung entstandenen Schaden.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Bei Ausfall oder Beschädigung des Kilometerzählers ist der Vermieter berechtigt, falls sich die tatsächliche während der Mietdauer zurückgelegte Fahrtstrecke nicht
              anders feststellen lässt, das Mietentgelt um 150 km/Tag zusätzlich gemäß der Tariftabelle zu erhöhen.{' '}
            </span>
          </p>
        </li>
      </ol>
      <h3>
        <span>C: Auswahl des Kraftfahrzeuges </span>
      </h3>
      <ol className="ml-8 py-8 !list-decimal">
        <li>
          <p>
            <span>Reservierungen können für bestimmte Fahrzeuge vorgenommen werden. </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Wenn ein reserviertes Fahrzeug nicht innerhalb von einer Stunde nach dem vereinbarten Anmietzeitpunkt abgeholt oder der Vermieter nicht innerhalb einer Stunde über
              eine Terminverschiebung oder Stornierung informiert wird, behält sich der Vermieter das Recht vor, eine Stornogebühr gemäß den Bestimmungen und Preisen der jeweils
              gültigen aktuellen Tariftabelle zu verrechnen.{' '}
            </span>
          </p>
        </li>
      </ol>
      <h3>
        <span>D: Vorzulegende Dokumente bei Fahrzeugabholung, berechtigte Fahrer, zulässige Nutzungen, Fahrten ins Ausland </span>
      </h3>
      <ol className="ml-8 py-8 !list-decimal">
        <li>
          <p>
            <span>
              Der Mieter muss mindestens drei Jahr im Besitz eines in Österreich gültigen Führerscheins sein. Bei der Fahrzeugübergabe muss der Mieter seinen Führerschein
              vorweisen.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Kann der Mieter bei der Übergabe des Fahrzeugs diese Dokumente nicht vorlegen, wird der Vermieter vom Mietvertrag zurücktreten; Ansprüche des Mieters wegen
              Nichterfüllung sind in diesen Fällen ausgeschlossen. Der Vermieter behält sich das Recht vor, in diesem Fall eine Stornogebühr gemäß den Bestimmungen und Preisen der
              jeweils gültigen aktuellen Tariftabelle zu verrechnen.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Das Fahrzeug darf nur vom Mieter selbst oder einer vom Mieter im Vorhinein gegenüber dem Vermieter namentlich genannten Personen gelenkt werden. Des Weiteren müssen
              die Führerscheine der Zusatzfahrer dem Vermieter vorgelegt werden. Der Mieter hat im Falle, dass er das Fahrzeug nicht selbst lenkt, sämtliche sich aus dem
              Mietvertrag und diesen Bedingungen ergebenden Pflichten auf diese Person(en) zu überbinden.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>Der Mieter haftet für das Handeln von Personen, denen er mit Zustimmung des Vermieters das Fahrzeug überlassen hat. </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Eine allenfalls vereinbarte Haftungsbeschränkung wird nicht wirksam, wenn der Mieter oder der im Mietvertrag vermerkte Zusatzfahrer das Fahrzeug einem Dritten
              überlässt und in dieser Zeit ein Schaden am Fahrzeug eintritt.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>Das Fahrzeug darf nur im öffentlichen Straßenverkehr (einschließlich befestigter Privatstraßen und Parkplätzen) benutzt werden. </span>
          </p>
        </li>
        <li>
          <p>
            <span>Das Fahrzeug darf weiters nicht verwendet werden: </span>
          </p>
          <ul className="ml-8 py-8 list-roman">
            <li>
              <p>
                <span>
                  für Rennen und in rennähnlicher Art, die Betätigung der Launch Control ist verboten; ebenfalls Drifts oder ähnliche Fahrmanöver die zu einer übermäßigen Abnützung
                  der Reifen und des Kraftfahrzeuges führen;{' '}
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  von einer unter Einfluss von Alkohol, Medikamenten oder Drogen stehenden Person bzw. einer Person, die aufgrund anderer geführter Umstände fahruntüchtig ist,{' '}
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  zu motorsportlichen Zwecken, insbesondere Fahrveranstaltungen, bei denen es auf die Erzielung einer Höchstgeschwindigkeit ankommt, oder bei den dazugehörigen
                  Übungsfahrten,{' '}
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>für Fahrzeugtests oder Fahrsicherheitstrainings, </span>
              </p>
            </li>
            <li>
              <p>
                <span>zur gewerblichen Personenbeförderung, </span>
              </p>
            </li>
            <li>
              <p>
                <span>zur Weitervermietung, </span>
              </p>
            </li>
            <li>
              <p>
                <span>um ein anderes Fahrzeug oder einen Anhänger zu ziehen (außer mit der ausdrücklichen Genehmigung des Vermieters), zu schieben oder sonst zu bewegen, </span>
              </p>
            </li>
            <li>
              <p>
                <span>zur Begehung von Straftaten, auch wenn diese nur nach dem Recht des Tatorts mit Strafe bedroht sind, </span>
              </p>
            </li>
            <li>
              <p>
                <span>zur Beförderung von leicht entzündlichen, giftigen oder sonst gefährlichen Stoffen </span>
              </p>
            </li>
            <li>
              <p>
                <span>für Fahrten abseits befestigter Straßen. </span>
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            <span>
              Solange das Fahrzeug nicht benutzt wird, sind die Türen und Fenster stets versperrt bzw. geschlossen zu halten. Überhaupt hat der Mieter alle Vorkehrungen zu treffen,
              damit das Fahrzeug von unbefugten Personen nicht in Betrieb genommen werden kann.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Der Mieter ist verpflichtet, das von ihm im Fahrzeug verstaute Ladegut ordnungsgemäß (insbesondere gegen jegliches Verrutschen) zu sichern. Der sichere Betrieb des
              Fahrzeuges muss jederzeit gewährleistet sein. Es ist dafür Vorsorge zu treffen, dass andere Verkehrsteilnehmer nicht gefährdet werden und durch die Beladung keinerlei
              Beschädigung oder übermäßige Verschmutzung entsteht. Weiters hat der Mieter bzw. der im Mietvertrag eingetragene Fahrer dafür Sorge zu tragen, dass sämtliche
              mitfahrende Personen während der gesamten Fahrtdauer die vorhandenen Sicherheitsgurte vorschriftsgemäß benutzen.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Jedwede Veränderung am und im Fahrzeug ist dem Mieter untersagt; sollte der Mieter dennoch Veränderungen, welcher Art auch immer vornehmen, hat er für sämtliche
              Kosten der Wiederherstellung des ursprünglichen Zustands des Fahrzeugs aufzukommen.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Der Mieter ist nicht berechtigt, mit dem Fahrzeug außerhalb der Grenzen Österreichs zu fahren; es sei denn, es liegt ihm dazu die ausdrückliche schriftliche
              Zustimmung des Vermieters – samt den vereinbarten Sonderkonditionen vor.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Die Zustimmungserklärung ist den Behörden am Grenzübergang auf Verlangen vorzulegen. Die Zustimmung wird nach dem freien Ermessen des Vermieters erteilt; ein Anspruch
              auf Abgabe dieser Zustimmung besteht nicht. Der Mieter haftet dem Vermieter für den Fall einer nicht genehmigten bzw. vereinbarungswidrigen Fahrt außerhalb
              Österreichs für alle Nachteile die dem Vermieter daraus entstehen. Der Verstoß gegen das Verbot zur Durchführung von Fahrten außerhalb Österreichs stellt eine
              wesentliche Vertragsverletzung dar. Der Mieter nimmt zur Kenntnis, dass im Fall einer ungenehmigten Fahrt außerhalb Österreichs auch eine allenfalls vereinbarte
              Haftungsbeschränkung außer Kraft tritt.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Der Schaden des Vermieters umfasst im Einzelfall bei Verlust des Fahrzeuges dessen Verkehrswert zzgl. der entstehenden Manipulationskosten (Anund Abmeldung,
              Versicherungen, etc.). Der Mieter ist verpflichtet, im Fall einer genehmigten Fahrt außerhalb Österreichs sich über die einschlägigen Vorschriften des jeweiligen
              Landes zu informieren; insbesondere gilt dies für Mautpflicht, besondere Versicherungsberechtigungen und Führerscheinvoraussetzungen. Des Weiteren haftet der Mieter
              für alle inund ausländischen Verkehrs-, Mautund Parkübertretungen, sowie Abgaben jeglicher Form und Besitzstörungshandlungen. Der Mieter erteilt seine Zustimmung zur
              Einhebung einer Bearbeitungsgebühr über sein zur Verfügung gestelltes Zahlungsmittel. Das Fahrzeug darf im Ausland nur auf bewachten Parkplätzen abgestellt und der
              Fahrzeugschlüssel keinesfalls Dritten ausgehändigt werden, insbesondere auch nicht zur Aufbewahrung oder zum Rangieren des Fahrzeuges.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Jede schuldhafte, auch bloß fahrlässige Verletzung der obigen Bestimmungen macht den Mieter gegenüber dem Vermieter für jeglichen dadurch oder dabei entstandenen
              Schaden (einschließlich zweckentsprechender Rechtsverfolgungskosten) in vollem Umfang haftbar. Eine allenfalls vereinbarte Haftungsbeschränkung ist im Falle einer
              solchen Verletzung unwirksam.{' '}
            </span>
          </p>
        </li>
      </ol>
      <h3 className="pt-12">
        <span>E: Mietpreis, Verzugszinsen </span>
      </h3>
      <ol className="ml-8 py-8 !list-decimal">
        <li>
          <p>
            <span>Der Mieter schuldet ein Mietentgelt in der jeweils gesondert vereinbarten Höhe. </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Als Mietpreis gelten grundsätzlich die bei Anmietung gültigen Tarife gemäß der jeweiligen aktuellen Tariftabelle, deren Bedingungen am Standort des Vermieters
              aufliegen und auf der Homepage jederzeit abrufbar sind, sofern nicht ein besonderer Mietzins vereinbart ist. Im Mietpreis nicht enthalten sind Kosten für Betankung,
              Treibstoff, Servicegebühren sowie Zustellungsund Abholungskosten. Sonderpreise und Preisnachlässe gelten nur für den Fall der fristgerechten und vollständigen
              Zahlung. Bei schuldhaft nicht fristgerechter Bezahlung werden allenfalls gewährte Nachlässe gegenüber der aktuellen Preisliste nachbelastet.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Die Mietzinsforderungen des Vermieters sowie allfällige sonstige Forderungen aus dem Mietvertrag inkl. Schadenersatzansprüche sind jeweils sofort zur Zahlung fällig.
              Im Fall des Zahlungsverzuges verpflichtet sich der Mieter zur Bezahlung von Verzugszinsen in Höhe von 6 % p.a..{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Kosten für außergerichtliche oder gerichtliche Verfolgung des Anspruchs durch Inkassobüros und/oder einen Rechtsanwalt werden darüber hinaus in Rechnung gestellt.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Der Mieter hat ebenfalls die Kosten für zusätzlichen Aufwand des Vermieters zu tragen. Die gilt z.B. bei Verlust der Fahrzeugschlüsseln bzw. des Zulassungsscheines,
              allfälligen nicht retournierten Zubehörs, etc..{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>Ebenfalls die Kosten für allenfalls anfallende Stehzeiten für das Fahrzeug. </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Der Mieter ist grundsätzlich nicht verpflichtet, das Fahrzeug vor Rückstellung zu reinigen; im Fall einer erheblichen über den gewöhnlichen Gebrauch hinausgehenden
              Verschmutzung ist der Vermieter aber berechtigt, dem Mieter zusätzliche Reinigungskosten gemäß den Bestimmungen und Preisen der jeweils gültigen aktuellen
              Tariftabelle in Rechnung zu stellen.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>Sonderreinigungskosten werden nach tatsächlichem Aufwand verrechnet. </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Verkehrsund Parkstrafen, Abschleppkosten und dergleichen werden jeweils gesondert verrechnet. Ebenfalls eine Verwaltungspauschale gemäß den Bestimmungen und Preisen
              der jeweils gültigen aktuellen Tariftabelle.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>Eine digitale Jahresvignette für österreichische Autobahnen ist bei jedem Mietfahrzeug vorhanden und ist jedes Mietfahrzeug bereits registriert. </span>
          </p>
        </li>
        <li>
          <p>
            <span>Dies gilt nicht für die Brennerautobahn und sonstige mautpflichtige Straßen. </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Der Mieter hat für Benutzung dieser Straßen für die rechtzeitige und vollständige Entrichtung der Autobahnmaut zu sorgen und hält den Vermieter diesbezüglich schadund
              klaglos.{' '}
            </span>
          </p>
        </li>
      </ol>
      <h3>
        <span>F: Zahlungsbedingungen </span>
      </h3>
      <ol className="ml-8 py-8 !list-decimal">
        <li>
          <p>
            <span>
              Der Mietpreis (zzgl. sonstiger vereinbarter Entgelte, wie z.B. Haftungsfreistellungen, Zustellungskosten, etc.) zzgl. Umsatzsteuer ist für den gesamten vereinbarten
              Mietzeitraum in voller Höhe zu leisten, d.h. Rückerstattungen bei verspäteter Fahrzeugabholung oder vorzeitiger -rückgabe erfolgen nicht (es sei denn, diese
              Verkürzung der Nutzungsdauer wäre vom Vermieter genehmigt).{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Der Mieter nimmt zur Kenntnis, dass eine Abänderung des reservierten oder bei der Fahrzeugübernahme vereinbarten Mietzeitraumes zu einer Änderung des angewendeten
              Tarifs führen kann. Dadurch kann es zu einer Abweichung zwischen dem reservierten und dem tatsächlich in Rechnung gestellten Mietpreises kommen.{' '}
            </span>
          </p>
        </li>
      </ol>
      <h3>
        <span>G: Versicherung </span>
      </h3>
      <ol className="ml-8 py-8 !list-decimal">
        <li>
          <p>
            <span>
              Das gemietete Fahrzeug ist zu den in Österreich üblichen Versicherungsbedingungen sowie mit der für Österreich gültigen Mindestversicherungssumme
              haftpflichtversichert. Wird der Vermieter von dritter Seite aufgrund von Schäden, die vom Mieter oder eingetragenen Lenkern verursacht wurden, in Anspruch genommen,
              ohne dass dieser Versicherungsschutz (zur Gänze) greift, hat der Mieter dem Vermieter diesbezüglich gänzlich schadund klaglos zu halten.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Ausgenommen von der Versicherung ist jedenfalls die Verwendung der Fahrzeuge für die erlaubnispflichtige Beförderung gefährlicher Stoffe, sowie die vertragswidrige
              Nutzung des Mietfahrzeugs.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Eine Vollkaskoversicherung mit Selbstbehalt gemäß Tariftabelle wurde seitens des Vermieters abgeschlossen und ist hierfür kein gesondertes Entgelt zu bezahlen.{' '}
            </span>
          </p>
        </li>
      </ol>
      <h3>
        <span>H: Unfälle, Diebstahl, Anzeigenpflicht </span>
      </h3>
      <ol className="ml-8 py-8 !list-decimal">
        <li>
          <p>
            <span>
              Nach einem Unfall, Diebstahl, Brand oder Wildschaden hat der Mieter unverzüglich den Vermieter und die Polizei zu verständigen. Auch bei reinen Sachschäden ist die
              nächste Polizeidienststelle um Aufnahme der Unfallmeldung i.S.d. § 4 Abs. 5a StVO zu ersuchen. Sollte die Polizei die Unfallaufnahme verweigern, hat der Mieter dies
              gegenüber dem Vermieter in geeigneter Form (z.B. schriftliche Bestätigung der Polizei oder Angabe (einschließlich Tag und Uhrzeit), welche Polizeidienststelle
              telefonisch verständigt wurde, die Schadensaufnahme aber abgelehnt hat) nachzuweisen. Ist durch den Unfall kein Dritter geschädigt worden oder konnte bei reinen
              Sachschädenein Datenaustausch mit dem geschädigten Dritten i.S.d. § 4 Abs. 5 StVO erfolgen, kann die Verständigung der nächsten Polizeidienststelle ausnahmsweise mit
              Zustimmung des Vermieters unterbleiben. Der Mieter ist in einem solchen Fall aber jedenfalls verpflichtet, diesen Schaden unter Vorlage eines Unfallberichts im Sinne
              der nachstehenden Bestimmungen an den Vermieter zu melden. Wurde das Mietfahrzeug durch unbekannte Dritte beschädigt (Parkschäden, Unfall mit Fahrerflucht) hat der
              Mieter aber jedenfalls auch bei geringfügigen Schäden unverzüglich die nächste Polizeidienststelle zu verständigen und eine Aufnahme des Schadens zu verlangen. Der
              Mieter hat nach Möglichkeit zur Feststellung des Sachverhaltes beizutragen und alles zu unterlassen, was diese Feststellung erschwert oder verhindert. Des weiteren
              hat er Namen und Anschrift der Beteiligten und Zeugen festzuhalten, sowie die erforderliche Hilfe zu leisten. Der Mieter hat dafür Sorge zu tragen, dass das Fahrzeug
              nicht ohne angemessene Sicherheitsvorkehrungen zurückgelassen wird und unternimmt alles zur Schadensminimierung. Der Mieter wird keine Schuld eingestehen, keine
              Haftung anerkennen und keine Zahlung leisten.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Spätestens bei Rückgabe des Fahrzeuges ist eine vollständige Schadensmeldung in Form eines europäischen Unfallberichtes, unter Angabe des Sachverhaltes (dieser ist
              auf Verlangen, über die standardisiert vorgesehen Angaben hinaus, auch in Form einer detaillierten Schilderung des Unfallherganges schriftlich mitzuteilen) inklusive
              allfälliger Unfallzeugen, des Unfallgegners, dessen Haftpflichtversicherung, etc. vom Lenker unterschrieben, an den Vermieter abzugeben.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Wenn auch nur einer der vorgenannten Punkte nicht eingehalten wird, liegt eine wesentliche Vertragsverletzung vor und es treten somit auch die Haftungsbeschränkungen
              außer Kraft. Die Nichteinhaltung dieser Punkte kann auch dazu führen, dass eine Leistungsfreiheit des Haftpflichtversicherers eintritt. Tritt Leistungsfreiheit ein,
              ist der Mieter dem Vermieter für alle entstandenen Schäden ersatzpflichtig.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Der Mieter haftet unabhängig von einer allenfalls vereinbarten Haftungsbeschränkung gegenüber dem Vermieter für alle Schäden (insbesondere zweckentsprechende,
              notwendige und soweit es sich um außergerichtliche Geltendmachung handelt auch in einem angemessenen Verhältnis zur betriebenen Forderung stehende
              Rechtsverfolgungskosten), die aus von ihm schuldhaft unrichtig gemachten Angaben über den Unfallhergang resultieren.{' '}
            </span>
          </p>
        </li>
      </ol>
      <h3>
        <span>I: Haftung des Mieters, Vereinbarung der Haftungsbeschränkung </span>
      </h3>
      <ol className="ml-8 py-8 !list-decimal">
        <li>
          <p>
            <span>
              Sofern nicht im Einzelfall anderes ausdrücklich vereinbart, haftet der Mieter gegenüber dem Vermieter für alle Schäden am Fahrzeug und dessen Einrichtungen bzw. für
              den Verlust (Diebstahl u.ä.) des Fahrzeuges (und dessen Einrichtungen), soweit diese Schäden bzw. der Verlust zwischen der Übernahme des Fahrzeuges durch ihn und der
              Rückstellung desselben eingetreten sind und diese nicht durch die Haftpflichtund oder Vollkaskoversicherung abgedeckt sind. Diese Haftung ist nicht an ein Verschulden
              des Mieters an dem eingetretenen Schaden gebunden.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Insbesondere hat der Mieter das Fahrzeug in dem Zustand zurückzugeben, in dem er es übernommen hat (vorbehaltlich der für Mietdauer und zurückgelegte
              Kilometerleistung üblichen Abnützung).{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Der Mieter kann die Haftung für Schäden aus Unfällen/Diebstählen durch Zahlung eines besonderen Entgeltes lt. Preisliste auf den vereinbarten Selbstbehalt beschränken
              (vertragliche Haftungsbeschränkung). In diesem Fall haftet er für Schäden aus Verkehrsunfällen und/oder Diebstahl bzw. mutwillige Beschädigung des Fahrzeuges durch
              Dritte, sowie sonstige herbeigeführte Schäden über den vereinbarten Selbstbehalt hinaus nur dann, wenn:{' '}
            </span>
          </p>
          <ul className="ml-8 py-8 list-roman">
            <li>
              <p>
                <span>der Mieter das Leihfahrzeug an Personen die nicht im Mietvertrag vermerkt sind weitergibt; </span>
              </p>
            </li>
            <li>
              <p>
                <span>Schäden vom Mieter oder eingetragenen Fahrer grob fahrlässig oder durch Vorsatz verursacht wurden;</span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  der Lenker des Fahrzeuges zum Unfallzeitpunkt nicht über eine gültige Lenkerberechtigung verfügte oder die Fahrtüchtigkeit des Lenkers durch Alkohol, Drogen oder
                  durch einen anderen sonstigen Zustand die Reaktionsfähigkeit des Lenkers beeinträchtigt war (z.B. Ermüdung, Erkrankung, etc.);{' '}
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>das Fahrzeug zum Schadenszeitpunkt nicht gemäß den Nutzungsbedingungen des vorliegenden Mietvertrags genutzt wurde; </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  der Lenker seiner Verpflichtung im Falle eines Unfalles oder Schadens nicht bzw. nicht fristgerecht oder nicht vollständig nachkommt (siehe Punkt VIII.);{' '}
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>der Mieter oder der eingetragene Fahrer, Unfallflucht begangen hat; </span>
              </p>
            </li>
            <li>
              <p>
                <span>der Schaden nicht während der vereinbarten Mietvertragsdauer eingetreten ist (also insbesondere </span>
              </p>
              <p>
                <span>bei verspäteter Rückstellung des Fahrzeuges); </span>
              </p>
            </li>
            <li>
              <p>
                <span>der Schaden während einer unberechtigten Auslandsfahrt entstanden ist; </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Schäden durch eine unsachgemäße Beladung des Fahrzeuges, z.B. durch Ladegut oder Überladung entstanden sind, sowie Schäden an der Innenausstattung des Fahrzeuges;{' '}
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>Schäden die infolge nicht ausreichend gesicherter Ladung oder durch unsachgemäße Anbringung von Zubehör bzw. unpassendes Zubehör entstehen; </span>
              </p>
            </li>
            <li>
              <p>
                <span>Schäden durch eine Nichtbeachtung der Durchfahrtshöhe und -breite (z.B. Unterführungen, Garagen, etc.) entstanden sind; </span>
              </p>
            </li>
            <li>
              <p>
                <span>bei Diebstahl die Fahrzeugschlüssel vom Mieter nicht retourniert werden können; </span>
              </p>
            </li>
            <li>
              <p>
                <span>
                  Schäden verursacht werden, die im Zuge des Transports des Fahrzeuges mit anderen Verkehrsbzw. Beförderungsmitteln entstanden sind (z.B. auf Autoreisezügen,
                  Fährschiffen, Fahrzeugtransportern, etc.).{' '}
                </span>
              </p>
            </li>
            <li>
              <p>
                <span>Schäden welche durch Unterlassung des Liftsystemes verursacht werden; </span>
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            <span>
              Eine Haftungsbeschränkung im Sinne der vorstehenden Bedingung gilt weiters nicht für Schäden, die durch Bedienungsfehler, Fehlbetankung, vom Mieter verschuldete
              Bremsmanöver, unsachgemäße Handhabung von Schneeketten, Gepäckträgern und Zubehör, unsachgemäßer Beladung, Fahrten abseits befestigter Straßen, Nichtverschließen von
              Verdecken/Fenstern bei Regen und Wind, sowie bei ungenügender Fahrzeugsicherung (unverschlossenes Fahrzeug, Stecken-Lassen des Schlüssels) u.ä. eintreten. Ebenso
              wenig gilt sie für vom Mieter und seinen Beifahrern verursachte Beschädigungen oder Verschmutzungen des Fahrzeuginnenraumes (wie z.B. Brandlöcher in den Sitzen u.ä.)
              oder die Kosten der Ersatzbeschaffung verlorener Fahrzeugschlüssel oder Fahrzeugpapiere. In all diesen Fällen bleibt sohin trotz vertraglich vereinbarter
              Haftungsbeschränkung die Haftung des Mieters i.S. der obigen Bestimmung hinsichtlich des gesamten Schadens aufrecht.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Eine derartige vereinbarte Haftungsbeschränkung kann in keinem Fall eine Haftung vom Vermieter für vom Mieter in das Fahrzeug eingebrachte und dort beschädigte oder
              gestohlene Gegenstände auslösen.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Wird das Fahrzeug vom Mieter ohne geeignete Beaufsichtigung unzureichend gesichert (unversperrt bzw. mit im Fahrzeug zurückgelassenem Fahrzeugschlüssel) abgestellt
              oder werden vom Mieter im Fahrzeug Wertgegenstände in einer Weise zurückgelassen, sodass sie von außen sichtbar sind, so gelten Diebstähle bzw. Einbruchsdiebstähle
              jedenfalls als grob fahrlässig verursacht, sodass eine allenfalls vereinbarte Haftungsbeschränkung in diesem Falle nicht wirksam wird.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Wurde eine Haftungsbeschränkung vereinbart und liegt der tatsächliche Schaden unter dem vereinbarten Selbstbehalt, so wird lediglich der tatsächliche Schaden dem
              Mieter angelastet.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Sind zwischen Übernahme und Rückstellung des Fahrzeuges durch den Mieter mehrere Schäden am Fahrzeug entstanden, für die der Mieter nach den vorstehenden Bestimmungen
              einzustehen hat, die nicht aus einem einheitlichen Unfallgeschehen herrühren, so hat der Mieter bei vereinbarter Haftungsbeschränkung den vereinbarten Selbstbehalt
              pro Schadensfall zu leisten (die vorstehende Bestimmung über niedrigere Schadenshöhen gilt aber sinngemäß).{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Der Mieter haftet jedenfalls für während der Mietzeit von ihm selbst oder von Personen, für die er im Sinne der vorstehenden Bestimmungen einzustehen hat, begangene
              Verstöße gegen gesetzliche Bestimmungen, insbesondere Verkehrsund Ordnungsvorschriften. Der Mieter hält den Vermieter hinsichtlich sämtlicher aufgrund derartiger von
              ihm zu vertretender Verstöße ergangener Verwaltungsstrafen, Gebühren und sonstiger Kosten (insbesondere allfälliger angemessener Rechtsverfolgungskosten) schadund
              klaglos, die Behörden aufgrund solcher Verstöße vom Vermieter als Halter des Fahrzeuges einheben.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Der Vermieter wird bei diesbezüglichen Auskunftsersuchen von hierzu berechtigten Behörden die Daten des Mieters an dieselben weitergeben. Als Ausgleich für den
              Verwaltungsaufwand des Vermieters durch die Bearbeitung von Anfragen, die Behörden zur Ermittlung von während der Mietzeit begangener Ordnungswidrigkeiten und
              Straftaten an sie richten, erhält der Vermieter eine Bearbeitungsgebühr gemäß der jeweils aktuellen Tariftabelle.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>Dem Vermieter ist es unbenommen, einen weitergehenden nachweislichen Schaden geltend zu machen. </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Der Mieter hat bei Fahrten mit dem bzw. bei dem Abstellen des Fahrzeuges alle einschlägigen Vorschriften sowie Rechte Dritter zu beachten. Insbesondere darf das
              Fahrzeug ohne entsprechende Erlaubnis hierzu berechtigter Personen nicht auf Privatgrund Dritter abgestellt werden. Werden Verletzungen dieser Bestimmung von dritter
              Seite behauptet, wird der Vermieter auf entsprechende Anfrage hin, Name und Anschrift des Mieters diesem Dritten bekanntgeben, damit derselbe allfällige Ansprüche
              direkt gegenüber dem Mieter geltend machen kann. Wird der Vermieter dennoch von dritter Seite wegen Handlungen oder Unterlassungen des Mieters in Anspruch genommen
              (insbesondere im Wege von Besitzstörungsoder Unterlassungsklagen), so wird der Vermieter dem Mieter in diesen Verfahren den Streit verkünden, um ihm die Möglichkeit
              zu geben, die Ansprüche des Dritten abzuwehren. Ergibt sich aus den Verfahren, dass ein schuldhaftes Verhalten des Mieters oder von eingetragenen Lenkern für die er
              einzustehen hat, vorlag, so hat er den Vermieter hinsichtlich aller Schäden und Nachteile daraus (einschließlich der Verfahrenskosten) schadund klaglos zu halten.{' '}
            </span>
          </p>
        </li>
      </ol>
      <h3>
        <span>J: Rückgabe des Fahrzeugs </span>
      </h3>
      <ol className="ml-8 py-8 !list-decimal">
        <li>
          <p>
            <span>
              Der Mietvertrag endet zum vereinbarten Zeitpunkt (Tag und Uhrzeit) und kann im Rahmen des Vertrages mit vorheriger Zustimmung vom Vermieter verlängert werden, sofern
              der Mieter die Verlängerung dem Vermieter vor Ablauf der vereinbarten Mietzeit bekannt gibt und dieser einer Verlängerung zustimmt.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Der Mieter ist verpflichtet, sofern nicht im Mietvertrag ausdrücklich anders vereinbart wurde, das Fahrzeug am letzten Tag der Mietzeit dem Vermieter am vereinbarten
              Ort und zur vereinbarten Zeit, vollgetankt zurückzugeben. Das Fahrzeug ist bei der Rückgabe von eigenen Fahrnissen des Mieters oder ihm zuzurechnenden Personen zu
              räumen und zu reinigen. Der Vermieter haftet nicht für Sachen, die vom Mieter in das Fahrzeug eingebracht und dort gestohlen, beschädigt oder bei Rückgabe des
              Fahrzeuges zurückgelassen werden.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Der Mieter hat das Fahrzeug in dem Zustand zurückzustellen, wie er es übernommen hat, mit sämtlichem Zubehör und der vollständigen übergeben Ausrüstung.
              Betankungskosten gehen zu Lasten des Mieters. Sondertarife gelten nur für den angebotenen Zeitraum und bei vereinbarungskonformer Zahlung. Bei vom Mieter zu
              vertretender Überschreitung des Zeitraumes oder Zahlungsverzug gilt ab dem für die Rückstellung vereinbarten Zeitpunkt der Tarif gemäß der jeweils aktuellen
              Tariftabelle.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Stellt der Mieter das Fahrzeug nach Ablauf der vereinbarten Mietdauer (unter Berücksichtigung von Tag und Uhrzeit) nicht an den Vermieter zurück, ist dieser
              berechtigt, für den über die Vertragsdauer hinausgehenden Zeitraum ein Nutzungsentgelt in Höhe des Normaltarifs laut der in den Geschäftslokalen der Vermieterin
              ausliegenden oder telefonisch beim Vermieter erfragbaren aktuellen Preisliste zu verrechnen. Hat der Mieter ursprünglich einen Sondertarif (z.B. Wochenendtarif)
              gebucht, kann dieses zusätzliche Nutzungsentgelt lt. Preisliste pro Tag bzw. Kilometer auch deutlich höher sein als bei der ursprünglichen Buchung. Bei verspäteter
              Rückstellung des Fahrzeuges wird dabei pro begonnener 24 Stunden (berechnet ab dem vereinbarten RückgabeZeitpunkt) ein Tagesentgelt verrechnet.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Im Falle einer vom Mieter zu vertretenden verspäteten Rückstellung des Fahrzeuges ohne Zustimmung des Vermieters wirkt ab dem ursprünglich vereinbarten
              Rückstellungszeitpunkt eine allenfalls vereinbarte Haftungsbeschränkung nicht mehr.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Stellt der Mieter das Fahrzeug ohne Anwesenheit des Vermieters zurück, trägt er die Gefahr für das Fahrzeug bis zur tatsächlichen Inbesitznahme durch den Vermieter.
              Der Mieter hat dem Vermieter alle aus der vereinbarungswidrigen Rückstellung des Fahrzeugs entstehende Schäden bzw. Nachteile zu ersetzen.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Sofern der Mieter das Fahrzeug nicht in Anwesenheit des Vermieters zurückstellt, wird es vom Vermieter mit dem ausdrücklichen Vorbehalt einer späteren Überprüfung und
              Schadensfeststellung in den Besitz rückübernommen.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Sollte eine Feststellung des Fahrzeugzustandes, insbesondere einer Überprüfung auf zusätzliche bzw. neu aufgetretene Beschädigungen aufgrund der Verschmutzung des
              zurückgegebenen Fahrzeuges nicht möglich sein, behält sich der Vermieter das Recht vor, das Fahrzeug vor einer Bestätigung der Rücknahme und Kostenabrechnung zu
              reinigen.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>Der Vermieter kann den Mietvertrag fristlos kündigen, sofern </span>
          </p>
          <ul className="ml-8 py-8 list-roman">
            <li>
              <p>
                <span>der Mieter mit seinen Zahlungen aus dem Vertragsverhältnis mit dem Vermieter in Rückstand gerät, </span>
              </p>
            </li>
            <li>
              <p>
                <span>Bankeinzüge/-schecks/Kreditkartenabbuchungen in Zusammenhang mit dem Vertragsverhältnis mit dem Vermieter nicht eingelöst werden können, </span>
              </p>
            </li>
            <li>
              <p>
                <span>sonstige wesentliche vertragliche Verpflichtungen verletzt bzw. nicht erfüllt werden, </span>
              </p>
            </li>
            <li>
              <p>
                <span>der Mieter das vermietete Fahrzeug entgegen den Bestimmungen dieses Mietvertrages benutzt. </span>
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            <span>
              Kündigt der Vermieter einen Mietvertrag, ist der Mieter verpflichtet, das Fahrzeug samt Fahrzeugpapieren, sämtlichem Zubehör und aller Fahrzeugschlüssel unverzüglich
              an den Vermieter zurückzustellen. Dies kann vom Vermieter in mündlicher Form erfolgen. Widrigenfalls behält sich der Vermieter das Recht vor, daraus resultierende
              Kosten oder Umstände gemäß der jeweils aktuellen Tariftabelle dem Mieter in Rechnung zu stellen.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Im Falle eines Zahlungsverzuges oder sollte gegen eine sonstige Mietbestimmung jeglicher Art verstoßen werden, ist der Vermieter berechtigt, das vermietete Fahrzeug
              einzuziehen.{' '}
            </span>
          </p>
        </li>
      </ol>
      <h3>
        <span>K: Datenschutzklausel </span>
      </h3>
      <ol className="ml-8 py-8 !list-decimal">
        <li>
          <p>
            <span>
              Die persönlichen Daten des Mieters (einschließlich der mieterbezogenen Nutzungsund Fahrzeugdaten soweit dies zum Zweck der Durchführung des Mietverhältnisses
              erforderlich ist) werden vom Vermieter zur Durchführung der Vermietung verarbeitet und gespeichert. Der Mieter erteilt hiermit seine ausdrückliche Zustimmung, dass
              die im Mietvertrag angeführten persönlichen Daten, vom Vermieter zum Zwecke der elektronischen Übersendung von Informationen und Angeboten, der Feststellung des
              Mietverhaltens, sowie zur Feststellung der Kundenzufriedenheit verarbeitet werden. Der Mieter stimmt einer Übersendung mittels E-Mail bzw. telefonsicher
              Kontaktaufnahme ausdrücklich zu. Die Zustimmungen können jederzeit widerrufen werden.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Name, Anschrift und Anmietungsdaten werden bei begründeten behördlichen Anfragen an die jeweilige Behörde, bei behaupteter Verletzung der Rechte Dritter (z.B. bei
              Besitzstörung) an diesen Dritten übermittelt.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Der Mieter ist ausdrücklich damit einverstanden, dass die Mietfahrzeuge permanent mittels GPS überwacht werden und die Mietwagen demzufolge geortet werden können.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>Die Mieter erklären ihre ausdrückliche Zustimmung zur Aufzeichnung dieser Daten. </span>
          </p>
        </li>
      </ol>
      <h3>
        <span>L: Rechtswahl </span>
      </h3>
      <ol className="ml-8 py-8 !list-decimal">
        <li>
          <p>
            <span>
              Dieser Vertrag unterliegt österreichischem Recht unter Ausschluss seiner internationalen Verweisungsnormen. Die Verpflichtung des Mieters, bei Auslandsfahrten das
              jeweils lokal gültige Recht einzuhalten, bleibt davon unberührt.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>Sämtliche Rechte und Verpflichtungen aus dieser Vereinbarung gelten auch zugunsten und zulasten des berechtigten Fahrers. </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Mehrere Mieter haften für Forderungen vom Vermieter aus diesem Vertragsverhältnis zur ungeteilten Hand (d.h.: jeder haftet bis zur vollen Höhe der Forderung).
              Gleiches gilt für den Mieter einerseits und alle Personen, denen der Mieter das Fahrzeug (eingetragener Zusatzfahrer) zur Nutzung überlässt, andererseits.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Sollte eine Bestimmung des Mietvertrages oder dieser Bedingungen unwirksam sein oder werden, so berührt dies nicht die Wirksamkeit der übrigen Bestimmungen.{' '}
            </span>
          </p>
        </li>
      </ol>
      <h3>
        <span>M: Gerichtsstand, Schriftform </span>
      </h3>
      <ol className="ml-8 py-8 !list-decimal">
        <li>
          <p>
            <span>
              Mündliche Nebenabsprachen bestehen nicht bzw. treten mit Unterfertigung des Vertrages außer Kraft. Änderungen, auch dieser Bestimmung, bedürfen der Schriftform. Dies
              gilt jedoch nicht gegenüber Verbrauchern im Sinne des Konsumentenschutzgesetzes.{' '}
            </span>
          </p>
        </li>
        <li>
          <p>
            <span>
              Alleiniger Gerichtsstand ist das für Innsbruck sachlich zuständige Gericht. Ist der Mieter Verbraucher i.S. des Konsumentenschutzgesetzes, ist Gerichtsstand das für
              den Wohnsitz des Mieters zuständige Gericht. Der Mieter kann Klagen gegen den Vermieter aber auch an dem zuvor genannten Gericht einbringen.{' '}
            </span>
          </p>
        </li>
      </ol>
    </>
  );
}

export default agbText;
